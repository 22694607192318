const axios = require('@/store/axios.config').default;

const resource = '/exchange';

const listCoin = async () => {
    const {data} = await axios.get(`${resource}/list`);
    // const data = [
    //     {
    //         createdAt: '2023-10-18T02:13:28.471Z',
    //         currentPrice: 0.00019,
    //         description: 'Cặp giao dịch hot nhất Herobook',
    //         fee: 0.002,
    //         id: '652f3f48bd8aa4262afdb0b4',
    //         isOpen: true,
    //         item: 'XIN',
    //         maxBuyPrice: 0.000924,
    //         minSellPrice: 0.000924,
    //         name: 'XIN/USDT',
    //         slug: 'xinCoin-usdt',
    //         updatedAt: '2023-12-18T14:47:08.741Z',
    //     },
    // ];
    return data;
};
const tradingList = async (slug) => {
    const {data} = await axios.get(`${resource}/${slug}`);
    // const data = {
    //     sell: [
    //         {
    //             _id: 0.000924,
    //             totalAmount: 1519,
    //             totalPrice: 1.403556,
    //         },
    //         {
    //             _id: 0.00093,
    //             totalAmount: 97662,
    //             totalPrice: 90.82566,
    //         },
    //         {
    //             _id: 0.001,
    //             totalAmount: 96128.39221811,
    //             totalPrice: 96.12839221811001,
    //         },
    //         {
    //             _id: 0.00102,
    //             totalAmount: 27358,
    //             totalPrice: 27.905160000000002,
    //         },
    //         {
    //             _id: 0.00105,
    //             totalAmount: 60300,
    //             totalPrice: 63.31499999999999,
    //         },
    //         {
    //             _id: 0.00106,
    //             totalAmount: 20000,
    //             totalPrice: 21.2,
    //         },
    //         {
    //             _id: 0.00108,
    //             totalAmount: 43883,
    //             totalPrice: 47.39364,
    //         },
    //         {
    //             _id: 0.00109,
    //             totalAmount: 20302,
    //             totalPrice: 22.12918,
    //         },
    //         {
    //             _id: 0.0011,
    //             totalAmount: 26198,
    //             totalPrice: 28.817800000000002,
    //         },
    //         {
    //             _id: 0.0012,
    //             totalAmount: 1000,
    //             totalPrice: 1.2,
    //         },
    //     ],
    //     buy: [],
    // };
    return data;
};
const orderBuy = async ({ idCoin, amount, price }) => {
    const data = await axios.post(`${resource}/order-buy`, {
        _id: idCoin,
        amount,
        price,
    });
    return data;
};
const orderSell = async ({ idCoin, amount, price }) => {
    const data = await axios.post(`${resource}/order-sell`, {
        _id: idCoin,
        amount,
        price,
    });
    return data;
};
const cancelOrderBuy = async (id) => {
    const data = await axios.delete(`${resource}/cancel-buy/${id}`);
    return data;
};
const cancelOrderSell = async (id) => {
    const data = await axios.delete(`${resource}/cancel-sell/${id}`);
    return data;
};
const detailCrypto = async (id) => {
    const {data} = await axios.get(`${resource}/list/${id}`);
    // const data = {
    //     fee: 0.002,
    //     isOpen: true,
    //     minSellPrice: 0.000924,
    //     maxBuyPrice: 0.000924,
    //     currentPrice: 0.00019,
    //     _id: '652f3f48bd8aa4262afdb0b4',
    //     name: 'XIN/USDT',
    //     description: 'Cặp giao dịch hot nhất Herobook',
    //     item: 'XIN',
    //     slug: 'xinCoin-usdt',
    //     __v: 0,
    //     createdAt: '2023-10-18T02:13:28.471Z',
    //     updatedAt: '2023-12-18T14:47:08.741Z',
    //     volume: 0,
    //     poolFee: 335.2607256569477,
    // };
    return data;
};
const dataChart = async ({ query, id }) => {
    const {data} = await axios.get(`${resource}/chart/${id}`, {
        params: query,
    });
    // const data = [
    //     {
    //         date: '2024-07-11T04:32:48.527Z',
    //         price: null,
    //     },
    //     {
    //         date: '2024-07-12T04:32:48.531Z',
    //         price: null,
    //     },
    //     {
    //         date: '2024-07-13T04:32:48.533Z',
    //         price: null,
    //     },
    //     {
    //         date: '2024-07-14T04:32:48.536Z',
    //         price: null,
    //     },
    //     {
    //         date: '2024-07-15T04:32:48.537Z',
    //         price: null,
    //     },
    //     {
    //         date: '2024-07-16T04:32:48.538Z',
    //         price: null,
    //     },
    //     {
    //         date: '2024-07-17T04:32:48.540Z',
    //         price: null,
    //     },
    // ];
    return data;
};
const historyOpen = async ({ query, coin }) => {
    const {data} = await axios.get(`${resource}/open-trade/${coin}`, {
        params: query,
    });
    // console.log(coin);
    // const data = {
    //     results: [],
    //     page: 1,
    //     limit: 10,
    //     totalPages: 0,
    //     totalResults: 0,
    // };
    return data;
};
const historyClose = async ({ query, coin }) => {
    const {data} = await axios.get(`${resource}/history-trade/${coin}`, {
        params: query,
    });
    // const data = {
    //     results: [],
    //     page: 1,
    //     limit: 10,
    //     totalPages: 0,
    //     totalResults: 0,
    // };
    return data;
};
const historyLast = async ({ query, coin }) => {
    const {data} = await axios.get(`${resource}/history-last/${coin}`, {
        params: query,
    });
    // const data = {
    //     results: [
    //         {
    //             _id: '6551d3a1c698d42ba1fd482e',
    //             sellId: '6551d25fc698d42ba1fd4614',
    //             buyId: '6551d3a1c698d42ba1fd4826',
    //             amountMatch: 60000,
    //             priceMatch: 0.00019,
    //             fee: 0.0228,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'sell',
    //             createdAt: '2023-11-13T07:43:29.862Z',
    //             updatedAt: '2023-11-13T07:43:29.862Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '6551bb22c698d42ba1fd315c',
    //             sellId: '6545dfc0c698d42ba1f39a26',
    //             buyId: '6551bb22c698d42ba1fd3154',
    //             amountMatch: 1470,
    //             priceMatch: 0.00055,
    //             fee: 0.0016170000000000002,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'sell',
    //             createdAt: '2023-11-13T05:58:58.066Z',
    //             updatedAt: '2023-11-13T05:58:58.066Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653e0c77c698d42ba1ed3c4e',
    //             sellId: '653e0c77c698d42ba1ed3c46',
    //             buyId: '653e0c70c698d42ba1ed3c27',
    //             fee: 2.402,
    //             amountMatch: 1654344,
    //             priceMatch: 0.000726,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-29T07:40:39.960Z',
    //             updatedAt: '2023-10-29T07:40:39.960Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653e0c68c698d42ba1ed3c0c',
    //             sellId: '653e0c68c698d42ba1ed3c04',
    //             buyId: '653e0c62c698d42ba1ed3beb',
    //             fee: 2.407,
    //             amountMatch: 1657659,
    //             priceMatch: 0.000726,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-29T07:40:24.703Z',
    //             updatedAt: '2023-10-29T07:40:24.703Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653e0c40c698d42ba1ed3b6c',
    //             sellId: '653e0c40c698d42ba1ed3b64',
    //             buyId: '653e0c34c698d42ba1ed3b28',
    //             fee: 0.072,
    //             amountMatch: 49533.71779077011,
    //             priceMatch: 0.000726,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-29T07:39:44.479Z',
    //             updatedAt: '2023-10-29T07:39:44.479Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653e0c3ac698d42ba1ed3b49',
    //             sellId: '653e0c3ac698d42ba1ed3b41',
    //             buyId: '653e0c34c698d42ba1ed3b28',
    //             fee: 2.34,
    //             amountMatch: 1611448.28220923,
    //             priceMatch: 0.000726,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-29T07:39:38.771Z',
    //             updatedAt: '2023-10-29T07:39:38.771Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653e0c1fc698d42ba1ed3b0d',
    //             sellId: '653e0c1fc698d42ba1ed3b05',
    //             buyId: '653e0c13c698d42ba1ed3ac9',
    //             fee: 0.077,
    //             amountMatch: 52862.71779077011,
    //             priceMatch: 0.000726,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-29T07:39:11.745Z',
    //             updatedAt: '2023-10-29T07:39:11.745Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653e0c19c698d42ba1ed3aea',
    //             sellId: '653e0c19c698d42ba1ed3ae2',
    //             buyId: '653e0c13c698d42ba1ed3ac9',
    //             fee: 2.34,
    //             amountMatch: 1611448.28220923,
    //             priceMatch: 0.000726,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-29T07:39:05.454Z',
    //             updatedAt: '2023-10-29T07:39:05.454Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653e0c06c698d42ba1ed3aae',
    //             sellId: '653e0c06c698d42ba1ed3aa6',
    //             buyId: '653e0befc698d42ba1ed3a6a',
    //             fee: 0.082,
    //             amountMatch: 56198.71779077011,
    //             priceMatch: 0.000726,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-29T07:38:46.045Z',
    //             updatedAt: '2023-10-29T07:38:46.045Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653e0bfcc698d42ba1ed3a8b',
    //             sellId: '653e0bfcc698d42ba1ed3a83',
    //             buyId: '653e0befc698d42ba1ed3a6a',
    //             fee: 2.34,
    //             amountMatch: 1611448.28220923,
    //             priceMatch: 0.000726,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-29T07:38:36.093Z',
    //             updatedAt: '2023-10-29T07:38:36.093Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653dd3bbc698d42ba1ed0b43',
    //             sellId: '653a2c29c698d42ba1ea0621',
    //             buyId: '653dd3bbc698d42ba1ed0b3b',
    //             amountMatch: 16340,
    //             priceMatch: 0.000752,
    //             fee: 0.024575359999999997,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'sell',
    //             createdAt: '2023-10-29T03:38:35.324Z',
    //             updatedAt: '2023-10-29T03:38:35.324Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653a2dedc698d42ba1ea0c89',
    //             sellId: '653a2dedc698d42ba1ea0c81',
    //             buyId: '653a2de6c698d42ba1ea0c62',
    //             fee: 2.402,
    //             amountMatch: 1601167,
    //             priceMatch: 0.00075,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-26T09:14:21.333Z',
    //             updatedAt: '2023-10-26T09:14:21.333Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653a2dd5c698d42ba1ea0c47',
    //             sellId: '653a2dd5c698d42ba1ea0c3f',
    //             buyId: '653a2dcfc698d42ba1ea0c20',
    //             fee: 2.407,
    //             amountMatch: 1604376,
    //             priceMatch: 0.00075,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-26T09:13:57.674Z',
    //             updatedAt: '2023-10-26T09:13:57.674Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653a2dc4c698d42ba1ea0c05',
    //             sellId: '653a2dc4c698d42ba1ea0bfd',
    //             buyId: '653a2dbbc698d42ba1ea0bde',
    //             fee: 2.411,
    //             amountMatch: 1607591,
    //             priceMatch: 0.00075,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-26T09:13:40.887Z',
    //             updatedAt: '2023-10-26T09:13:40.887Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653a2db2c698d42ba1ea0bc3',
    //             sellId: '653a2db2c698d42ba1ea0bbb',
    //             buyId: '653a2daac698d42ba1ea0b9c',
    //             fee: 2.416,
    //             amountMatch: 1610812,
    //             priceMatch: 0.00075,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-26T09:13:22.261Z',
    //             updatedAt: '2023-10-26T09:13:22.261Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653a2da0c698d42ba1ea0b81',
    //             sellId: '653a2da0c698d42ba1ea0b79',
    //             buyId: '653a2d97c698d42ba1ea0b5a',
    //             fee: 2.421,
    //             amountMatch: 1614040,
    //             priceMatch: 0.00075,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-26T09:13:04.589Z',
    //             updatedAt: '2023-10-26T09:13:04.589Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653a2d89c698d42ba1ea0aff',
    //             sellId: '653a2d89c698d42ba1ea0af5',
    //             buyId: '653a2d83c698d42ba1ea0ab2',
    //             fee: 2.426,
    //             amountMatch: 1617275,
    //             priceMatch: 0.00075,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-26T09:12:41.527Z',
    //             updatedAt: '2023-10-26T09:12:41.527Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653a2d79c698d42ba1ea0a5e',
    //             sellId: '653a2d79c698d42ba1ea0a56',
    //             buyId: '653a2d71c698d42ba1ea0a0a',
    //             fee: 2.431,
    //             amountMatch: 1620516,
    //             priceMatch: 0.00075,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-26T09:12:25.336Z',
    //             updatedAt: '2023-10-26T09:12:25.336Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653a2d69c698d42ba1ea09bc',
    //             sellId: '653a2d69c698d42ba1ea09b4',
    //             buyId: '653a2d61c698d42ba1ea0968',
    //             fee: 2.436,
    //             amountMatch: 1623764,
    //             priceMatch: 0.00075,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-26T09:12:09.085Z',
    //             updatedAt: '2023-10-26T09:12:09.085Z',
    //             __v: 0,
    //         },
    //         {
    //             _id: '653a2d55c698d42ba1ea090b',
    //             sellId: '653a2d55c698d42ba1ea0903',
    //             buyId: '653a2d4fc698d42ba1ea08ba',
    //             fee: 2.441,
    //             amountMatch: 1627019,
    //             priceMatch: 0.00075,
    //             pair: '652f3f48bd8aa4262afdb0b4',
    //             type: 'buy',
    //             createdAt: '2023-10-26T09:11:49.804Z',
    //             updatedAt: '2023-10-26T09:11:49.804Z',
    //             __v: 0,
    //         },
    //     ],
    //     page: 1,
    //     limit: 20,
    //     totalPages: 15,
    //     totalResults: 300,
    // };
    return data;
};
const InfoPoolPublic = async (id) => {
    const data = await axios.get(`api/e-nft/profit/${id}`);
    return data;
};
const InfoDividend = async () => {
    const data = await axios.get('api/stock/config');
    return data;
};
const HistoryProfit = async (query) => {
    const data = await axios.get(`api/invest/history`, {
        params: query,
    });
    return data;
};
const UserNFT = async () => {
    const data = await axios.get(`/api/e-nft/myNFT`);
    return data;
};
export {
    listCoin,
    tradingList,
    orderBuy,
    orderSell,
    cancelOrderBuy,
    cancelOrderSell,
    dataChart,
    historyOpen,
    historyClose,
    detailCrypto,
    historyLast,
    InfoPoolPublic,
    InfoDividend,
    HistoryProfit,
    UserNFT,
};
