const merchant = require('../axios.merchant').default;

export default {
    namespaced: true,
    state: () => ({
        corpoPackages: null,
        merchantList: null,
        scanHistory: null,
    }),
    getters: {
        CorpoPackages: (state) => state.corpoPackages,
        MerchantList: (state) => state.merchantList,
        ScanHistory: (state) => state.scanHistory
    },
    actions: {
        // async getCorpoPackages({commit}) {
        //     return merchant.get('mining/business-node-settings').then((res) => {
        //         if (res && res.status && res.data) {
        //             commit('SET_CORPO_NODE', res.data);
        //         }
        //     });
        // },
        async merchantList({commit,rootGetters}) {
            return merchant.post('/merchant/list', {
                email: rootGetters['info/UserInfo']?.email
            }).then((res) => {
                if (res && res.status) {
                    commit('SET_MERCHANT_NODE', res.data)
                }
            });
        },
        async post_merchantCode(context, input) {
            return merchant.post('code/submit', input).then((res) => {
                console.log(res);
                return res;
            });
        },
        async get_scanHistory({ commit, rootGetters }, input) {
            return merchant
                .post('merchant/history-code', {
                    email: rootGetters['info/UserInfo']?.email,
                    page: input ? input.page : 1
                })
                .then((res) => {
                    commit('SET_SCAN_HISTORY', res.data);
                    return res;
                });
        },
    },
    mutations: {
        SET_CORPO_NODE(state, data) {
            state.corpoPackages = data;
        },
        SET_SCAN_HISTORY(state, data) {
            state.scanHistory = data;
        },
        SET_MERCHANT_NODE(state, data) {
            state.merchantList = data
        }
    },
};
