<template>
    <div class="gotit-wrap">
        <div v-if="isVisible" class="history">
            <div @click="toggleComponent">{{ $t('history') }}</div>
        </div>
        <div v-if="!isVisible" class="back" @click="toggleComponent">
            <div class="goback">{{ $t('goBack') }}</div>
            <a
                class="howtouse"
                href="https://www.gotit.vn/how-to-use"
                target="_blank"
                >{{ $t('howToUse') }}</a
            >
        </div>
        <div>
            <GotItXnode v-if="isVisible" />
            <GotItXnodeHistory v-if="!isVisible" />
        </div>
    </div>
</template>

<script>
import GotItXnode from './GotItXnode.vue';
import GotItXnodeHistory from './GotItXnodeHistory.vue';

export default {
    data() {
        return {
            isVisible: true,
        };
    },
    components: {
        GotItXnode,
        GotItXnodeHistory,
    },

    methods: {
        toggleComponent() {
            this.isVisible = !this.isVisible;
        },
    },
};
</script>

<style lang="scss" scoped>
.gotit-wrap {
    margin-top: 2rem;
}
.history {
    text-align: right;
    padding-right: 15px;

    div {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        padding: 0px 10px;
        position: relative;
        line-height: 1.6;
        display: inline-block;
        background-color: #0087cc;
        filter: drop-shadow(1px 1px 2px #a1a1a1);
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
        &::before {
            content: '';
            position: absolute;
            border: 0.8em solid #0087cc;
            border-left-color: transparent;
            right: 100%;
            top: 0;
            bottom: 0;
        }
        &::after {
            content: '';
            position: absolute;
            border: 0.8em solid transparent;
            border-color: transparent transparent transparent #0087cc;
            left: 100%;
            top: 0;
            bottom: 0;
        }
    }
}

.back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-bottom: 15px;

    .goback {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        padding: 0px 10px;
        position: relative;
        line-height: 1.6;
        display: inline-block;
        background-color: #0087cc;
        filter: drop-shadow(1px 1px 2px #a1a1a1);
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
        &::before {
            content: '';
            position: absolute;
            border: 0.8em solid transparent;
            border-right-color: transparent;
            border-color: transparent #0087cc transparent transparent;
            right: 100%;
            top: 0;
            bottom: 0;
        }
        &::after {
            content: '';
            position: absolute;
            border: 0.8em solid #0087cc;
            border-right-color: transparent;
            left: 100%;
            top: 0;
            bottom: 0;
        }
    }

    .howtouse {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        padding: 4px 15px;
        position: relative;
        line-height: 1.6;
        display: inline-block;
        background-color: #0087cc;
        filter: drop-shadow(1px 1px 2px #a1a1a1);
        border-radius: 8px;

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
