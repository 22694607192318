<template>
    <div v-if="Show" class="banner" id="notice-tick">
        <div
            :style="{ '--moveIt': isOverflow }"
            :class="{ isOverflow: isOverflow }"
        >
            <template v-for="(rule, index) in RuleSet">
                <div v-if="rule.show" :key="index" @click="rule.action">
                    {{ rule.title }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'StringBanner',
    data() {
        return {
            width: 0,
            scrollWidth: 0,
        };
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
        }),
        isOverflow() {
            if (this.width < this.scrollWidth) {
                return `translateX(-${this.scrollWidth - this.width}px)`;
            }
            return false;
        },

        RuleSet() {
            return [
                {
                    title: 'Update your sponsor now to get the most out of our website',
                    action: () => {
                        this.$bvModal.show('update-sponsor');
                    },
                    show:
                        this.UserInfo &&
                        this.UserInfo.sponsors &&
                        this.UserInfo.sponsors.length === 1,
                },
                {
                    title: this.$t('manaTooLowToMine'),
                    action: () => {
                        this.$router.push({ name: 'XnodeInventory' });
                    },
                    show: this.UserInfo.mana < 10,
                },
            ];
        },
        Show() {
            return this.RuleSet.find((el) => {
                return el.show;
            });
        },
    },
    methods: {
        handleResize() {
            const el = document.getElementById('notice-tick');
            if (el) {
                this.width = el.offsetWidth;
                this.scrollWidth = el.scrollWidth;
            }
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>

<style lang="scss" scoped>
.banner {
    background-color: #fecaca;
    color: #431407;
    font-weight: 600;
    text-align: center;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    > div {
        display: inline-block;
        white-space: nowrap;
        padding-right: 40px;
        right: 0px;
        > div {
            display: inline-block;
            padding: 0px 15px;
            cursor: pointer;
            transition: 0.2s;
            &:hover {
                text-decoration: underline;
                color: #451a03;
            }
        }
    }
}

.isOverflow {
    animation: scroll 10s linear infinite;
}
@keyframes scroll {
    20% {
        transform: translateX(0);
    }
    100% {
        transform: var(--moveIt);
    }
}
</style>
