const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        member: {
            total: 1,
            list: [],
            totalRef: 0,
            totalRefF1: 0,
            refs: {},
            commissions: [],
        },
        statistical: {},
        comissionHistory: {},
        totalFSale: null,
        totalGroupMint: null,
        totalGroupSale: null,
        maxoutChart: null,
    }),

    getters: {
        Member: (state) => state.member,
        CommissionHistory: (state) => state.comissionHistory,
        Statistic: (state) => state.statistical,
        TotalFSale: (state) => state.totalFSale,
        TotalGroupMint: (state) => state.totalGroupMint,
        TotalGroupSale: (state) => state.totalGroupSale,
        MaxoutChart: (state) => state.maxoutChart,
    },

    actions: {
        req_getListMember: ({ commit }, input) => {
            axios
                .get('/user/get-downlines', {
                    params: {
                        search: input.search || null,
                        page: input.page || 1,
                        limit: 4,
                        address: input.address,
                    },
                })
                .then((res) => {
                    commit('SET_LIST_MEMBER', res.data);
                });
        },
        req_getDownline: ({ commit }) => {
            axios.get('/user/ref-statistic').then((res) => {
                commit('SET_DOWNLINE', res.data);
            });
        },
        req_getCommissionStat: ({ commit }) => {
            axios.get('/user/commission-statistic').then((res) => {
                commit('SET_COMMISSION_STAT', res.data);
            });
        },
        req_getCommissionHistory: ({ commit }, input) => {
            axios
                .get('/user/activity-history', {
                    params: { type: 'commission', limit: 5, page: input.page },
                })
                .then((res) => {
                    commit('SET_HISTORY', res.data);
                });
        },
        req_getStatistical: async ({ commit }) => {
            return axios.get('/statistical/member').then((res) => {
                if (res.data) {
                    commit('SET_STATISTICAL', res.data);
                }
            });
        },
        async reqTotalFSale({ commit }) {
            return axios.get('/statistical/total-f-sale').then((res) => {
                if (res && res.status) {
                    commit('SET_TOTAL_F_SALE', res.data);
                }
            });
        },
        async reqTotalGroupMint({ commit }) {
            return axios.get('/statistical/total-group-mint').then((res) => {
                if (res && res.status) {
                    commit('SET_TOTAL_GROUP_MINT', res.data);
                }
            });
        },
        async reqTotalGroupSale({ commit }) {
            return axios.get('/statistical/total-group-sale').then((res) => {
                if (res && res.status) {
                    commit('SET_TOTAL_GROUP_SALE', res.data);
                }
            });
        },
        async reqChartData({ commit }) {
            return axios.get('/user/chart-data').then((res) => {
                if (res.status) {
                    commit('SET_CHART_DATA', res.data);
                }
            });
        },
    },
    mutations: {
        SET_LIST_MEMBER(state, data) {
            state.member.total = data.totalPages;
            state.member.list = data.results;
            state.member.totalRef = data.totalRef;
            state.member.totalRefF1 = data.totalRefF1;
        },
        SET_DOWNLINE(state, data) {
            state.member.refs = data;
        },
        SET_COMMISSION_STAT(state, data) {
            state.member.commissions = data;
        },
        SET_HISTORY(state, data) {
            state.comissionHistory = data;
        },
        SET_STATISTICAL(state, data) {
            state.statistical = data;
        },
        SET_TOTAL_F_SALE(state, data) {
            state.totalFSale = data;
        },
        SET_TOTAL_GROUP_MINT(state, data) {
            state.totalGroupMint = data;
        },
        SET_TOTAL_GROUP_SALE(state, data) {
            state.totalGroupSale = data;
        },
        SET_CHART_DATA(state, data) {
            state.maxoutChart = data;
        },
    },
};
