<template>
    <div class="internet-bill container">
        <router-link :to="{ name: 'Utilities' }" class="breadcrumbs">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    d="M7.828 11H20v2H7.828l5.364 5.364l-1.414 1.414L4 12l7.778-7.778l1.414 1.414z"
                />
            </svg>
            {{ $t('internetPayment') }}
        </router-link>

        <div class="mt-4">
            <h3 class="head">
                {{ $t('getPaymentInfo') }}
            </h3>
            <form class="my-form" @submit.prevent="getBill">
                <label>
                    {{ $t('billType') }}:
                    <input :value="'Internet'" readonly />
                </label>
                <label>
                    {{ $t('provider') }}:
                    <select v-model="provider">
                        <option
                            v-for="option in providers"
                            :key="option.value"
                            :value="option.value"
                        >
                            {{ option.text }}
                        </option>
                    </select>
                </label>
                <label>
                    {{ $t('customerCode') }}:
                    <input v-model="code" required />
                </label>
                <button type="submit" class="botan">{{ $t('submit') }}</button>
            </form>
        </div>
        <div class="base-table my-4">
            <b-table
                v-if="history && history.results"
                striped
                hover
                :items="history.results"
                show-empty
                :fields="fields"
                thead-class="custom-header"
                responsive
            >
                <template #empty>
                    <h4 class="text-center my-3">
                        {{ $t('noData') }}
                    </h4>
                </template>
                <template #head()="{ label }">
                    {{ $t(label) }}
                </template>
                <template #cell()="{ value }">
                    <span>{{ value }}</span>
                </template>
                <template #cell(status)="{ item }">
                    <template v-if="item.type_payment === 'check'">
                        <span
                            v-if="item.status === -1"
                            class="status-color failed"
                        >
                            {{ $t('failed') }}
                        </span>
                        <span
                            v-else-if="item.status === 2"
                            class="status-color success"
                        >
                            {{ $t('paymentSuccess') }}
                        </span>
                        <span
                            v-else-if="item.status === 1"
                            class="status-color pending"
                        >
                            {{ $t('processingTransaction') }}
                        </span>
                        <span v-else-if="item.status === 0 && item.bill_value">
                            {{ $t('paymentAvailable') }}
                        </span>
                        <span v-else>
                            {{ $t('inquiringBill') }}
                        </span>
                    </template>
                    <template v-else-if="item.type_payment === 'payment'">
                        <span
                            v-if="item.status === -1"
                            class="status-color failed"
                        >
                            {{ $t('failed') }}
                        </span>
                        <span
                            v-else-if="item.status === 2"
                            class="status-color success"
                        >
                            {{ $t('paymentSuccess') }}
                        </span>
                        <span
                            v-else-if="item.status === 1"
                            class="status-color pending"
                        >
                            {{ $t('processingTransaction') }}
                        </span>
                        <span v-else>
                            {{ $t('inquiringBill') }}
                        </span>
                    </template>
                </template>
                <template #cell(#)="row">
                    <div>
                        <b-button
                            size="sm"
                            variant="info"
                            @click="row.toggleDetails"
                            class="px-2 py-0"
                        >
                            {{ row.detailsShowing ? $t('hide') : $t('show') }}
                        </b-button>
                    </div>
                </template>
                <template #row-details="{ item }">
                    <div class="more-details details">
                        <div v-if="item.currency === 'xinCoin'">
                            <div>{{ $t('payWith') }}:</div>
                            <div class="value">
                                <img
                                    src="@/assets/images/logo/xin.png"
                                    width="18px"
                                />
                            </div>
                        </div>
                        <div v-if="item.currency === 'USDT'">
                            <div>{{ $t('payWith') }}:</div>
                            <div class="value">
                                <img
                                    src="@/assets/images/logo/usdt.png"
                                    width="18px"
                                />
                            </div>
                        </div>
                        <div
                            v-if="
                                item.bill_value &&
                                item.rateVNDToUSDT &&
                                item.rateUSDTToXin
                            "
                        >
                            <div>{{ $t('price') }}</div>
                            <div class="value">
                                {{
                                    TruncateToDecimals2(
                                        item.bill_value /
                                            item.rateVNDToUSDT /
                                            item.rateUSDTToXin,
                                    )
                                }}
                                <img
                                    v-if="item.currency === 'xinCoin'"
                                    src="@/assets/images/logo/xin.png"
                                    width="18px"
                                />
                                <img
                                    v-else
                                    src="@/assets/images/logo/usdt.png"
                                    width="18px"
                                />
                            </div>
                        </div>
                        <div v-if="item.bill_value">
                            <div>{{ $t('cost') }}</div>
                            <div class="value">
                                {{ TruncateToDecimals2(item.bill_value) }}
                                VNĐ
                            </div>
                        </div>
                        <div v-if="item.fullName">
                            <div>{{ $t('name') }}</div>
                            <div class="value">
                                {{ item.fullName }}
                            </div>
                        </div>
                        <div v-if="item.ky">
                            <div>{{ $t('period') }}</div>
                            <div class="value">
                                {{ item.ky }}
                            </div>
                        </div>
                        <div v-if="item.message">
                            <div>{{ $t('comment') }}:</div>
                            <div class="value">
                                {{ item.message }}
                            </div>
                        </div>
                        <div v-if="item.createdAt">
                            <div>{{ $t('createdDate') }}:</div>
                            <div class="value">
                                {{ getDateTime3(item.createdAt) }}
                            </div>
                        </div>
                        <span class="botans">
                            <button
                                v-if="
                                    item.type_payment === 'check' &&
                                    item.status === 0 &&
                                    item.bill_value
                                "
                                class="botan"
                                @click="
                                    $router.push({
                                        name: 'InternetBill',
                                        params: { id: item.id },
                                    })
                                "
                            >
                                {{ $t('billDetail') }}
                            </button>
                            <button
                                v-if="
                                    item.type_payment === 'check' &&
                                    item.status === 0
                                "
                                class="botan cancel"
                                @click="cancelConfirm(item)"
                            >
                                {{ $t('cancel') }}
                            </button>
                        </span>
                    </div>
                </template>
            </b-table>
        </div>
        <Paginate
            v-if="history"
            @current="onChangePage"
            :totalPages="history.totalPages"
        />
    </div>
</template>
<script>
import Paginate from '@/components/shared/Paginate.vue';

export default {
    name: 'InternetPayment',
    components: {
        Paginate,
    },
    data() {
        return {
            code: '',
            pending: false,
            providers: [
                {
                    value: 'VIETTEL',
                    text: 'Tập đoàn Viettel',
                },
                {
                    value: 'FPT',
                    text: 'FPT Telecom',
                },
                {
                    value: 'SPT',
                    text: 'Bưu chính Viễn thông Sài Gòn SPT',
                },
                {
                    value: 'VNPT',
                    text: 'Tập đoàn Bưu chính Viễn thông Việt Nam VNPT',
                },
            ],
            provider: 'VIETTEL',
            history: null,
            historyQuery: {
                page: 1,
                limit: 10,
                category: 'INTERNET',
                sortBy: 'createdAt:desc',
            },
            fields: [
                {
                    class: 'text-center',
                    label: 'category',
                    key: 'category',
                },
                {
                    class: 'text-center',
                    label: 'provider',
                    key: 'type_category',
                },
                {
                    class: 'text-center',
                    label: 'customerCode',
                    key: 'customer_code',
                },
                {
                    class: 'text-center',
                    label: 'status',
                    key: 'status',
                },
                {
                    key: '#',
                    label: 'more',
                    class: 'text-center',
                },
            ],
        };
    },
    methods: {
        getBill() {
            if (this.pending) {
                return;
            }
            this.pending = true;

            this.$store
                .dispatch('utilities/getBill', {
                    type_category: this.provider,
                    customer_code: this.code,
                    category: 'INTERNET',
                    color: 'T',
                })
                .then((res) => {
                    if (res.status) {
                        this.$store
                            .dispatch('utilities/getHistory', {
                                page: 1,
                                limit: 10,
                                status: 0,
                            })
                            .then((response) => {
                                if (
                                    response &&
                                    response.status &&
                                    response.data.results &&
                                    response.data.results.length > 0
                                ) {
                                    this.$router.push({
                                        name: 'InternetBill',
                                        params: {
                                            id: response.data.results[
                                                response.data.results.length - 1
                                            ].id,
                                        },
                                    });
                                }
                            });
                        return this.reqHistory();
                    }
                    return false;
                })
                .finally(() => {
                    this.pending = false;
                });
        },
        async reqHistory() {
            const res = await this.$store.dispatch(
                'utilities/getHistory',
                this.historyQuery,
            );
            if (res.status) {
                this.history = res.data;
            }
            return res;
        },
        cancelConfirm(item) {
            this.$store.commit('utilities/SET_CANCELING_BILL', item);
            this.$bvModal.show('cancel-bill');
        },
        onChangePage(payload) {
            this.historyQuery.page = payload;
            this.reqHistory();
        },
    },
    created() {
        this.reqHistory();
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'utilities/HISTORY_CHANGED':
                    this.reqHistory();
                    break;
                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>
<style lang="scss" scoped>
.internet-bill {
    padding-top: 30px;
    padding-bottom: 30px;
}
.breadcrumbs {
    font-size: 1.25em;
    color: #1478b0;
    font-weight: 600;
    transition: 0.2s;
    &:hover {
        color: #22578a;
    }
    @media (min-width: 992px) {
        font-size: 1.75em;
    }
}
.head {
    text-align: center;
    max-width: 700px;
    margin: auto;
    color: #1478b0;
    font-weight: 600;
    font-size: 1.5em;
    padding-bottom: 15px;
    @media (min-width: 992px) {
        font-size: 2em;
    }
}
.my-form {
    max-width: 700px;
    margin: auto;
    background-color: #1478b0;
    color: #fff;
    padding: 15px;
    border-radius: 16px;
    label {
        display: block;
        padding: 10px 0px;
        input {
            margin-top: 5px;
            display: block;
            width: 100%;
            min-width: none;
            border-radius: 100vw;
            padding: 5px 15px;
            border: none;
            outline: none;
            &[readonly] {
                background-color: #d9d9d9;
            }
        }
        select {
            display: block;
            width: 100%;
            border-radius: 100vw;
            padding: 5px 15px;
        }
        select {
            background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
                no-repeat;
            background-position: right 5px top; /* Change the px value here to change the distance */
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-color: #fff;
            display: block;
            width: 100%;
            border-radius: 100vw;
            padding: 5px 15px;
        }
    }
    .botan {
        color: #1478b0;
        background-color: #fff;
        border-radius: 6px;
        display: block;
        margin: auto;
    }
}
.base-table {
    border-radius: 22px;
}
</style>
