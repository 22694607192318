/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import store from '@/store';

const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        address: null,
        info: {
            email: null,
            address: null,
        },
        balance: 0,
        xnode: {},
        master: null,
        manaPrice: null,
    }),

    getters: {
        Address: (state) => state.address,
        UserInfo: (state) => state.info,
        Balance: (state) => state.balance,
        XNode: (state) => state.xnode,
        Master: (state) => state.master,
        ManaPackage: (state) => state.manaPackage,
        ManaPrice: (state) => state.manaPrice,
    },

    actions: {
        req_getInfo: async ({ commit }) => {
            return axios.get('user/info').then((res) => {
                if (res && res.data) {
                    commit('GET_INFO_SUCCESS', res.data);
                    return res;
                }
                return false;
            });
        },
        req_getXnode: ({ commit }) => {
            axios.get('/user/my-xnode').then((res) => {
                if (res && res.data) {
                    commit('SET_XNODE', res.data);
                }
            });
        },
        get_aboutXnode: ({ dispatch }) => {
            dispatch('info/req_getXnode', null, { root: true });
            dispatch('mining/get_nodeStat', null, { root: true });
            dispatch('mining/get_userNodes', null, { root: true });
            dispatch('mining/get_userGift', null, { root: true });
        },
        get_refreshBalance: ({ dispatch }, input) => {
            if (input === 'xinCoin') {
                axios.get(`/user/refresh-xin`).then(() => {
                    dispatch('req_getInfo');
                });
            } else {
                axios.get(`/user/refresh-usd`).then(() => {
                    dispatch('req_getInfo');
                });
            }
        },
        async buyMana(context, input) {
            return axios.post('/user/buy-mana', input).then((res) => {
                console.log(res);
            });
        },
        async buyManaPackage(context, input) {
            return axios.post('/user/buy-package-mana', input).then((res) => {
                console.log(res);
            });
        },
        async manaPrice({ commit }) {
            return axios.get('/user/mana-price').then((res) => {
                if (res && res.status) {
                    commit('SET_MANA_PRICE', res.data);
                }
                return res.status;
            });
        },
        async transferMana(context, input) {
            return axios.post('/user/transfer-mana', input).then(res =>{ 
                console.log(res);
            })
        },
        get_masterXNode: async ({ commit }) => {
            return axios.get('masternode/info').then((res) => {
                if (res && res.status) {
                    commit('SET_MASTER', res.data);
                }
                return res.status;
            });
        },
    },
    mutations: {
        SET_ADDRESS: (state, data) => {
            state.address = data.address;
        },
        GET_INFO_SUCCESS: (state, data) => {
            state.info = data;
            state.balance =
                Math.round(parseFloat(data.xinCoin) * 10000) / 10000;
            if (state.info.unknownUser) state.unKnown = true;
            store.commit('wallet/setupWallet', data.address);
        },
        SET_XNODE: (state, data) => {
            state.xnode = data;
        },
        SET_MASTER: (state, data) => {
            state.master = data;
        },
        SET_MANA_PRICE: (state, data) => {
            state.manaPrice = data;
        },
    },
};
