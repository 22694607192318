<template>
    <div class="utility-history">
        <h2>{{ $t('history') }}</h2>
        <div class="user-voucher-container">
            <div id="util-table" class="base-table">
                <b-table
                    v-if="History && History.results"
                    striped
                    hover
                    :items="History.results"
                    show-empty
                    :fields="fields"
                    thead-class="custom-header"
                    responsive
                >
                    <template #empty>
                        <h4 class="text-center my-3">
                            {{ $t('noData') }}
                        </h4>
                    </template>
                    <template #head()="{ label }">
                        {{ $t(label) }}
                    </template>
                    <template #cell()="{ value }">
                        <span>{{ value }}</span>
                    </template>
                    <template #cell(status)="{ item }">
                        <template v-if="item.type_payment === 'check'">
                            <span
                                v-if="item.status === -1"
                                class="status-color failed"
                            >
                                {{ $t('failed') }}
                            </span>
                            <span
                                v-else-if="item.status === 2"
                                class="status-color success"
                            >
                                {{ $t('paymentSuccess') }}
                            </span>
                            <span
                                v-else-if="item.status === 1"
                                class="status-color pending"
                            >
                                {{ $t('processingTransaction') }}
                            </span>
                            <span
                                v-else-if="item.status === 0 && item.bill_value"
                            >
                                {{ $t('paymentAvailable') }}
                            </span>
                            <span v-else>
                                {{ $t('inquiringBill') }}
                            </span>
                        </template>
                        <template v-else-if="item.type_payment === 'payment'">
                            <span
                                v-if="item.status === -1"
                                class="status-color failed"
                            >
                                {{ $t('failed') }}
                            </span>
                            <span
                                v-else-if="item.status === 2"
                                class="status-color success"
                            >
                                {{ $t('paymentSuccess') }}
                            </span>
                            <span
                                v-else-if="item.status === 1"
                                class="status-color pending"
                            >
                                {{ $t('processingTransaction') }}
                            </span>
                            <span v-else>
                                {{ $t('inquiringBill') }}
                            </span>
                        </template>
                    </template>
                    <template #cell(#)="row">
                        <div>
                            <b-button
                                size="sm"
                                variant="info"
                                @click="row.toggleDetails"
                                class="px-2 py-0"
                            >
                                {{
                                    row.detailsShowing ? $t('hide') : $t('show')
                                }}
                            </b-button>
                        </div>
                    </template>
                    <template #row-details="{ item }">
                        <div class="more-details details">
                            <div v-if="item.currency === 'xinCoin'">
                                <div>{{ $t('payWith') }}:</div>
                                <div class="value">
                                    <img
                                        src="@/assets/images/logo/xin.png"
                                        width="18px"
                                    />
                                </div>
                            </div>
                            <div v-if="item.currency === 'USDT'">
                                <div>{{ $t('payWith') }}:</div>
                                <div class="value">
                                    <img
                                        src="@/assets/images/logo/usdt.png"
                                        width="18px"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    item.bill_value &&
                                    item.rateVNDToUSDT &&
                                    item.rateUSDTToXin
                                "
                            >
                                <div>{{ $t('price') }}</div>
                                <div class="value">
                                    {{
                                        TruncateToDecimals2(
                                            item.bill_value /
                                                item.rateVNDToUSDT /
                                                item.rateUSDTToXin,
                                        )
                                    }}
                                    <img
                                        v-if="item.currency === 'xinCoin'"
                                        src="@/assets/images/logo/xin.png"
                                        width="18px"
                                    />
                                    <img
                                        v-else
                                        src="@/assets/images/logo/usdt.png"
                                        width="18px"
                                    />
                                </div>
                            </div>
                            <div v-if="item.bill_value">
                                <div>{{ $t('cost') }}</div>
                                <div class="value">
                                    {{ TruncateToDecimals2(item.bill_value) }}
                                    VNĐ
                                </div>
                            </div>
                            <div v-if="item.fullName">
                                <div>{{ $t('name') }}</div>
                                <div class="value">
                                    {{ item.fullName }}
                                </div>
                            </div>
                            <div v-if="item.ky">
                                <div>{{ $t('period') }}</div>
                                <div class="value">
                                    {{ item.ky }}
                                </div>
                            </div>
                            <div v-if="item.message">
                                <div>{{ $t('comment') }}:</div>
                                <div class="value">
                                    {{ item.message }}
                                </div>
                            </div>
                            <div v-if="item.createdAt">
                                <div>{{ $t('createdDate') }}:</div>
                                <div class="value">
                                    {{ getDateTime3(item.createdAt) }}
                                </div>
                            </div>
                            <span class="botans">
                                <button
                                    v-if="
                                        item.type_payment === 'check' &&
                                        item.status === 0 &&
                                        item.bill_value
                                    "
                                    class="botan"
                                    @click="
                                        reqBillDetail(item.category, item.id)
                                    "
                                >
                                    {{ $t('billDetail') }}
                                </button>
                                <button
                                    v-if="
                                        item.type_payment === 'check' &&
                                        item.status === 0
                                    "
                                    class="botan cancel"
                                    @click="cancelConfirm(item)"
                                >
                                    {{ $t('cancel') }}
                                </button>
                            </span>
                        </div>
                    </template>
                </b-table>
            </div>
            <Paginate
                v-if="History"
                @current="onChangePage"
                :totalPages="History.totalPages"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Paginate from '@/components/shared/Paginate.vue';

export default {
    name: 'UtilityPage',
    components: {
        Paginate,
    },
    data() {
        return {
            fields: [
                {
                    class: 'text-center',
                    label: 'category',
                    key: 'category',
                },
                {
                    class: 'text-center',
                    label: 'provider',
                    key: 'type_category',
                },
                {
                    class: 'text-center',
                    label: 'customerCode',
                    key: 'customer_code',
                },
                {
                    class: 'text-center',
                    label: 'status',
                    key: 'status',
                },
                {
                    key: '#',
                    label: 'more',
                    class: 'text-center',
                },
            ],
            subscriber: null,
            params: {
                page: 1,
                limit: 10,
                sortBy: 'createdAt:desc',
            },
            pending: false,
            utilityHistory: null,
        };
    },

    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
        }),
        History() {
            return this.utilityHistory;
        },
    },

    methods: {
        async req_history() {
            const res = await this.$store.dispatch(
                'utilities/getHistory',
                this.params,
            );
            if (res.status) {
                this.utilityHistory = res.data;
            }
        },
        onChangePage(payload) {
            this.params.page = payload;
            this.req_history();
        },
        reqBillDetail(category, id) {
            if (category === 'ELECTRIC') {
                this.$router.push({
                    name: 'ElectricBill',
                    params: { id },
                });
            } else if (category === 'INTERNET') {
                this.$router.push({
                    name: 'InternetBill',
                    params: { id },
                });
            }
        },
        async checkStatus(category, id) {
            if (this.pending) {
                return;
            }
            this.pending = true;

            if (category === 'PHONE' || category === 'VETC') {
                await this.$store.dispatch('utilities/getPaymentStatus', id);
                await this.req_history();
            } else if (category === 'ELECTRIC' || category === 'INTERNET') {
                await this.$store.dispatch('utilities/getBillStatus', id);
                await this.req_history();
            }
            this.pending = false;
        },
        cancelConfirm(item) {
            this.$store.commit('utilities/SET_CANCELING_BILL', item);
            this.$bvModal.show('cancel-bill');
        },
    },

    created() {
        this.req_history();
        this.subscriber = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'utilities/PAYMENT_SUCCESS':
                case 'utilities/HISTORY_CHANGED':
                    this.req_history();
                    break;
                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.subscriber();
    },
};
</script>

<style lang="scss">
.utility-history {
    margin-top: 2rem;

    h2 {
        color: #0087cc;
        font-weight: 600;
    }

    .user-voucher-container {
        margin-top: 2rem;
        .base-table {
            border-radius: 22px;
        }
    }
    .more-details {
        background-color: #4393c0;
        border-radius: 6px;
        padding: 5px;
        box-shadow: 1px 1px 4px 0px #a1a1a1;
        > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 3px 0px;
            color: #f3f4f6;
            .value {
                font-family: 600;
                color: white;
            }
        }
        .botans {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
        }
        .botan {
            border-radius: 100vw;
            color: #1478b0;
            background-color: #fff;
            font-weight: 700;
            padding: 5px 15px;
            &:hover {
                background-color: #eeeeee;
            }
            &:active {
                background-color: #e6e6e6;
            }
            &.cancel {
                background-color: #ef4444;
                color: #fff;

                &:hover {
                    background-color: #dc2626;
                }
                &:active {
                    background-color: #b91c1c;
                }
            }
        }
        @media (min-width: 768px) {
            padding: 5px 15px;
        }
    }
}
</style>
