const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        promotion: null,
    }),
    getters: {
        Promotion: (state) => state.promotion,
    },
    mutations: {
        SET_PROMOTION(state, data) {
            state.promotion = data;
        },
    },
    actions: {
        async claim_promotion7(context, input) {
            return axios.post('/promotion/promotion-7', input);
        },
        async getPromotionInfo({ commit }) {
            return axios.get('promotion/list').then((res) => {
                if (res && res.status) {
                    commit('SET_PROMOTION', res.data);
                }
            });
        },
    },
};
