<template>
    <div class="container py-2">
        <div class="banners">
            <img
                style="max-width: 100%"
                class="d-none d-md-inline"
                src="@/assets/images/mockup/utilLong.png"
            />
            <img
                style="max-width: 100%"
                class="d-md-none"
                src="@/assets/images/mockup/util.png"
            />
        </div>
        <template v-if="true">
            <div class="listing">
                <div class="head">{{ $t('utility') }}</div>
                <div class="list">
                    <div class="wrap">
                        <div class="voucher">
                            <div class="branding">
                                <!-- <img src="@/assets/images/mockup/electric.jpg" /> -->
                            </div>
                            <div class="content">
                                <div class="tit">
                                    <span>{{ $t('electric') }}</span>
                                </div>
                                <div class="prize">
                                    <img
                                        src="@/assets/images/icons/convert.svg"
                                    />
                                    <div>{{ $t('electricPayment') }}</div>
                                </div>
                                <div
                                    class="d-flex justify-content-between align-items-end"
                                >
                                    <button
                                        class="buy"
                                        @click="
                                            $router.push({
                                                name: 'ElectricPayment',
                                            })
                                        "
                                    >
                                        {{ $t('payment') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wrap">
                        <div class="voucher">
                            <div class="branding">
                                <!-- <img src="@/assets/images/mockup/electric.jpg" /> -->
                            </div>
                            <div class="content">
                                <div class="tit">
                                    <span>{{ $t('internet') }}</span>
                                </div>
                                <div class="prize">
                                    <img
                                        src="@/assets/images/icons/convert.svg"
                                    />
                                    <div>{{ $t('internetPayment') }}</div>
                                </div>
                                <div
                                    class="d-flex justify-content-between align-items-end"
                                >
                                    <button
                                        class="buy"
                                        @click="
                                            $router.push({
                                                name: 'InternetPayment',
                                            })
                                        "
                                    >
                                        {{ $t('payment') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wrap">
                        <div class="voucher">
                            <div class="branding">
                                <!-- <img src="@/assets/images/mockup/electric.jpg" /> -->
                            </div>
                            <div class="content">
                                <div class="tit">
                                    <span>{{ $t('phone') }}</span>
                                </div>
                                <div class="prize">
                                    <img
                                        src="@/assets/images/icons/convert.svg"
                                    />
                                    <div>{{ $t('phonePayment') }}</div>
                                </div>
                                <div
                                    class="d-flex justify-content-between align-items-end"
                                >
                                    <button
                                        class="buy"
                                        @click="$bvModal.show('phone-payment')"
                                    >
                                        {{ $t('payment') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wrap">
                        <div class="voucher">
                            <div class="branding">
                                <!-- <img src="@/assets/images/mockup/electric.jpg" /> -->
                            </div>
                            <div class="content">
                                <div class="tit">
                                    <span>{{ $t('vetc') }}</span>
                                </div>
                                <div class="prize">
                                    <img
                                        src="@/assets/images/icons/convert.svg"
                                    />
                                    <div>{{ $t('vetcPayment') }}</div>
                                </div>
                                <div
                                    class="d-flex justify-content-between align-items-end"
                                >
                                    <button
                                        class="buy"
                                        @click="$bvModal.show('vetc-payment')"
                                    >
                                        {{ $t('payment') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UtilitiesHistory />
            <PhonePayment />
            <VETCPayment />
        </template>

        <template v-else>
            <div class="maintenance">
                <h1>{{ $t('featureInMaintenance') }}</h1>
                <img src="@/assets/images/mockup/maintenance.png" />
            </div>
        </template>
    </div>
</template>
<script>
import UtilitiesHistory from '@/components/utilities/UtilitiesHistory.vue';
import PhonePayment from '../../components/utilities/PhonePayment.vue';
import VETCPayment from '../../components/utilities/VETCPayment.vue';

export default {
    name: 'UtilityPage',
    components: {
        UtilitiesHistory,
        PhonePayment,
        VETCPayment,
    },
};
</script>

<style lang="scss" scoped>
.banners {
    > img {
        border-radius: 16px;
    }
}

.listing {
    padding: 10px 0px;
    margin-top: 15px;

    .head {
        font-size: 32px;
        color: #0087cc;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .list {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        @media (min-width: 768px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media (min-width: 1280px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        gap: 10px;

        > .wrap {
            flex: 1 1 410px;
            max-width: 480px;
            min-width: 0px;

            .voucher {
                display: flex;

                .branding {
                    border-radius: 8px;
                    aspect-ratio: 1;
                    background-color: #a0d3f8;
                    // padding: 5px;
                    mask-image: radial-gradient(
                            circle at top right,
                            transparent 8px,
                            black 8px
                        ),
                        radial-gradient(
                            circle at bottom right,
                            transparent 8px,
                            black 8px
                        );
                    mask-type: alpha;
                    mask-composite: intersect;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    > img {
                        width: 108px;

                        @media (min-width: 992px) {
                            width: 137px;
                        }
                    }
                }

                .content {
                    border-radius: 8px;
                    background-color: #22578a;
                    color: white;
                    padding: 10px;
                    flex: 1;
                    mask-image: radial-gradient(
                            circle at top left,
                            transparent 12px,
                            black 12px
                        ),
                        radial-gradient(
                            circle at bottom left,
                            transparent 12px,
                            black 12px
                        );
                    mask-type: alpha;
                    mask-composite: intersect;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .tit {
                        font-size: 14px;
                        text-transform: uppercase;
                        display: flex;
                        justify-content: space-between;

                        @media (min-width: 992px) {
                            font-size: 18px;
                        }
                    }

                    .buy {
                        color: #0087cc;
                        background-color: white;
                        font-weight: 600;
                        border-radius: 6px;
                        padding: 2px 35px;
                        outline: none;
                    }

                    .cost {
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 1;

                        > span {
                            font-size: 32px;
                        }

                        @media (min-width: 992px) {
                            > span {
                                font-size: 50px;
                            }
                        }
                    }

                    .prize {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 16px;
                        font-weight: 600;

                        > img {
                            width: 20px;
                        }

                        @media (min-width: 992px) {
                            font-size: 18px;

                            > img {
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.my-voucher {
    margin-top: 15px;
    max-width: 465px;

    button {
        width: 100%;
        border-radius: 72px;
        background: linear-gradient(to right, #1775b8, #91c4e9);
        font-size: 18px;
        font-weight: 500;
        padding: 10px 15px;
        color: #fff;
        outline: none;
        transition: 0.2s;
        text-transform: uppercase;

        &:hover {
            opacity: 0.8;
        }
    }
}

.exchanging {
    h2 {
        color: #0087cc;
        font-weight: 600;
        font-size: 30px;
        text-align: center;
        margin-bottom: 15px;
    }

    h3 {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
    }

    p {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
    }

    .buttons {
        display: flex;
        justify-content: center;
        gap: 10px;

        button {
            max-width: 200px;
            width: 100%;
            padding: 4px 10px;
            border-radius: 72px;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 600;
            outline: none;

            &:nth-child(1) {
                // background: linear-gradient(to right, #1775B8, #91C4E9);
                color: #fff;
            }

            &:nth-child(2) {
                color: #000;
                border: 1px solid #0087cc;
            }
        }

        .close {
            color: #0087cc;
        }
    }

    @media (max-width: 768px) {
        h2 {
            font-size: 28px;
        }

        h3 {
            font-size: 22px;
        }

        p,
        button {
            font-size: 16px;
        }

        .buttons {
            button {
                padding: 5px 10px;
            }
        }
    }
}

.name {
    color: #0087cc;
    font-weight: 600;
}

.maintenance {
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    padding: 15px 5px;
    margin: 15px 0px;
    text-shadow: 1px 1px 1px #1775b8;
    background-color: #dddddd;
    > img {
        max-width: 330px;
        width: 100%;
        object-fit: contain;
    }
}
</style>

<style lang="scss">
#exchange-voucher {
    .modal-dialog {
        width: 90%;
        margin: 0 auto;
    }
}

::-webkit-scrollbar {
    width: 6px;
    display: block;
    padding-right: 5px;
}

::-webkit-scrollbar-track {
    border-radius: 12px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #0087cc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0087c8;
}
</style>
